.header {
  display: flex;
  position: fixed;
  top: 1;
  width: 90%;
}

.avatar {
  display: flex;
  position: absolute;
  right: 2%;
}
