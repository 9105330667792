.main {
  display: flex;
  justify-content: left;
  padding: 16;

  div {
    flex: 1;

    .main-contents {
      padding: 16;
    }
  }
}
